type AddClassType = {
  targetClassName: string
  addClassName: string
}

type RemoveClassType = {
  targetClassName: string
  removeClassName: string
}

type AnimationType = {
  delay?: number
} & (AddClassType | RemoveClassType)

type AnimationsType = {
  delay?: number
  changeClass: AddClassType[] | RemoveClassType[]
}

const isAddClassType = (args: any): args is AddClassType => {
  return !!args && typeof args.addClassName === 'string' && args.removeClassName === undefined && typeof args.targetClassName === 'string';
}

const isRemoveClassType = (args: any): args is RemoveClassType => {
  return !!args && typeof args.removeClassName === 'string' && args.addClassName === undefined && typeof args.targetClassName === 'string';
}

const isChangeClassType = (args: any): args is AddClassType | RemoveClassType => {
  return !!args && (isAddClassType(args) || isRemoveClassType(args));
}

const isAnimationType = (args: any): args is AnimationType => {
  return !!args && isChangeClassType(args);
}

const isAnimationsType = (args: any): args is AnimationsType => {
  return !!args && Array.isArray(args.changeClass) && args.changeClass.every(isChangeClassType);
}

const wait = (ms: number) => {
  return new Promise(resolve => setTimeout(resolve, ms));
}

const addClass = (targetClassName: string, addClassName: string) => {
  const elems = document.getElementsByClassName(targetClassName);
  for(let i=0; i < elems.length; i++) {
    elems[i].classList.add(addClassName);
  }
}

const removeClass = (targetClassName: string, addClassName: string) => {
  const elems = document.getElementsByClassName(targetClassName);
  for (let i = 0; i < elems.length; i++) {
    elems[i].classList.remove(addClassName);
  }
}

export const sequenceAnimation = async (animations: (AnimationType | AnimationsType)[]) => {
  for(let i=0; animations.length > i; i++) {
    const animation = animations[i];
    await wait(animation.delay ?? 0);
    if(isAnimationsType(animation)){
      animation.changeClass.forEach(anim => {
        if(isAddClassType(anim)){
          addClass(anim.targetClassName, anim.addClassName);
        } else if(isRemoveClassType(anim)){
          removeClass(anim.targetClassName, anim.removeClassName);
        }
      })
    } else if(isAnimationType(animation)){
      if(isAddClassType(animation)){
        addClass(animation.targetClassName, animation.addClassName);
      } else if(isRemoveClassType(animation)){
        removeClass(animation.targetClassName, animation.removeClassName);
      }
    }
  }
}