import React, { useState } from 'react';
import styles from './Header.module.scss'
import { OFFER_LINK } from '../constants/global';
import { useTransition, animated } from "react-spring";
import { HEADLINES, PC_HEADER_HEADLINES } from '../constants/global'
import { useWindowSizeKind } from '../hooks/useWindowSizeKind'
import { trackingOfferDetailsClickLink } from '../lib/tracking'

const clickLink = () => {
  trackingOfferDetailsClickLink('/', 'OfferDetailsHeader', OFFER_LINK, '募集詳細')
}

const Header = () => {
  const [ windowSizeKind ] = useWindowSizeKind();
  const [ isMenuOpen, setIsMenuOpen ] = useState(false);
  const transitions = useTransition(isMenuOpen, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: {
      duration: 170
    }
  });

  return (
    <React.Fragment>
      <header className={styles.headerWrapper}>
        <img src="/img/pc/iimon_logo.png" alt="iimon" className={styles.iimonLogo}/>
        <div className={styles.links}>
          {windowSizeKind === 'PC' && (
            <ol className={styles.items}>
              {PC_HEADER_HEADLINES.map((h, idx) => {
                return (
                  <li key={idx} className={styles.item}><a href={h.href}>{h.name}</a></li>
                )
              })}
            </ol>
          )}
          <a href={OFFER_LINK} target="_blank" rel="noreferrer" className={styles.recruitLink} onClick={clickLink}>募集詳細</a>
          <div className={styles.menuButton} onClick={() => setIsMenuOpen(true)}>
            <div className={styles.menuButtonInner} />
          </div>
        </div>
      </header>
      {transitions((style, item) => (
        item && (
          <animated.div style={style} className={styles.menuBg}>
            <div className={styles.closeButton} onClick={() => setIsMenuOpen(false)}>
              <div className={styles.closeButtonInner} />
            </div>
            <ol className={styles.menuItems}>
              {HEADLINES.map((h, idx) => (
                <li key={idx} className={styles.menuItem}>
                  <a href={h.href} onClick={() => setIsMenuOpen(false)}>{h.name}</a>
                </li>
              ))}
            </ol>
          </animated.div>
        )
      ))}
    </React.Fragment>
  )
}

export default Header;