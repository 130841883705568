import { useEffect } from 'react';
import styles from './FirstView.module.scss'
import { useTransition, useInView, animated } from '@react-spring/web';
import { sequenceAnimation } from '../animationUtil';
import { trackingViewPage } from '../lib/tracking'

const pics = ['0', '1', '2', '3', '4'];

const FirstView = () => {
  const [transitions, api] = useTransition(pics, () => ({
    delay: 600,
    from: { opacity: 0, transform: 'scale(0.5)' },
    enter: { opacity: 1, transform: 'scale(1)' },
    config: {
      mass: 1,
      tension: 700,
      friction: 20,
    },
    trail: 300,
  }))
  const [ref, inView] = useInView({once: true});

  useEffect(() => {
    if (inView) {
      api.start();
      sequenceAnimation([{
        delay: 300,
        changeClass: [{
          targetClassName: styles.upperBand,
          addClassName: styles.upperBandMoveRight0
        }, {
          targetClassName: styles.lowerBand,
          addClassName: styles.lowerBandMoveRight0
        }]
      }, {
        delay: 500,
        changeClass: [{
          targetClassName: styles.title,
          addClassName: styles.titleVisible
        }, {
          targetClassName: styles.upperBand,
          addClassName: styles.upperBandMoveRight1
        }, {
          targetClassName: styles.lowerBand,
          addClassName: styles.lowerBandMoveRight1
        }],
      }, {
        delay: 1300,
        targetClassName: styles.illustration,
        addClassName: styles.illustrationVisible
      }, {
        targetClassName: styles.title,
        addClassName: styles.titleTransition
      }, {
        delay: 400,
        targetClassName: styles.title,
        removeClassName: styles.titleVisible
      }, {
        delay: 400,
        targetClassName: styles.title,
        addClassName: styles.titleVisible
      }]);
    }
  }, [inView]);

  useEffect(() => {
    trackingViewPage(window.location.pathname, window.location.search);
    const style0 = 'font-weight: bold; font-size: 50px;color: red; text-shadow: 3px 3px 0 #FF5555, 6px 6px 0 #FF55FF, 9px 9px 0 #55FFFF, 12px 12px 0 #5555FF, 15px 15px 0 #044D91, 18px 18px 0 #2A1571';
    const style1 = 'background-color: #55FFFF; font-weight: bold;';
    console.log('%c JOIN US! \n\n%chttps://www.wantedly.com/companies/company_2248610', style0, style1);
  }, []);

  return (
    <section className={styles.wrapper}>
      <div className={styles.inner}>
        <div className={styles.titleWrapper}>
          <div className={styles.titleInner}>
            <h1 className={styles.title}>未来の日常を<br/>イイモンに変える</h1>
            <div className={styles.upperBandWrapper}>
              <div className={styles.upperBand}/>
            </div>
            <div className={styles.lowerBandWrapper}>
              <div className={styles.lowerBand} />
            </div>
          </div>
        </div>
      </div>
      <div ref={ref}>
        {inView && transitions((style, item) => (
          <animated.div style={style} className={styles[`pic${item}Bg`]}>
            <div className={styles[`pic${item}`]} />
          </animated.div>
        ))}
      </div>
      <img src="/img/pc/kv_illustration.png" alt="未来の日常をイイモンに変える" className={styles.illustration} />
    </section>
  );
}

export default FirstView;