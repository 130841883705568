import React, { useEffect } from 'react';
import cultures from '../contents/cultures.json'
import styles from './Culture.module.scss'
import { useWindowSizeKind } from '../hooks/useWindowSizeKind'
import { useCarouselScroll } from '../hooks/useCarouselScroll';
import { useInView } from '@react-spring/web';
import { trackingScrollPage } from '../lib/tracking'

const Culture = () => {
  const [ windowSizeKind ] = useWindowSizeKind();
  const item = document.getElementsByClassName(styles.cultureBox)[0];
  const scrollElement = document.getElementsByClassName(styles.cultureWrapper)[0] as HTMLElement;
  const [ itemIncrement, itemDecrement, currentItemNum ] = useCarouselScroll({item, scrollElement, maxItem: cultures.length - 1})
  const [trackingRef, trackingInView] = useInView({once: true});

  useEffect(() => {
    if (trackingInView) {
      trackingScrollPage('/', 'culture');
    }
  }, [trackingInView]);

  return (
    <section ref={trackingRef}>
      <div className={styles.cultureBgTop}/>
      <div className={styles.contentWrapper}>
        <div className={styles.bgRed}/>
        <div className={styles.cultureInner}>
          <h2 id="culture">CULTURE</h2>
          <h3>エンジニアのカルチャー</h3>
          <p className={styles.cultureText}>チームで協力して大きな力にできる様に、役職や経験年数など関係なく議論できるフラットな環境や{windowSizeKind === 'PC' && <br/>}困った時に相談したり、得意な分野で活躍する事ができる環境を作っています</p>
          <div className={styles.scrollButtons}>
            <div className={styles.cultureScroll}>
              <ol className={styles.cultureWrapper}>
                {cultures.map((culture, idx) => {
                  return (
                    <li key={idx} className={styles.cultureBox}>
                      <p className={styles.cultureTitle}>{culture.title}</p>
                      <p className={styles.cultureDetail} dangerouslySetInnerHTML={{__html: culture.detail}}></p>
                    </li>
                  )
                })}
              </ol>
            </div>
            {windowSizeKind === 'SP' && (
              <React.Fragment>
                <div onClick={itemDecrement} className={styles.leftScroll} style={currentItemNum <= 0 ? {display: 'none'} : {}}/>
                <div onClick={itemIncrement} className={styles.rightScroll} style={currentItemNum >= (cultures.length-1) ? {display: 'none'} : {}}/>
              </React.Fragment>
            )}
          </div>
          <img src="/img/pc/culture_illustration.png" alt="エンジニアのカルチャー" className={styles.cultureIllustration} />
        </div>
      </div>
      <div className={styles.cultureBgBottom}>
      </div>
    </section>
  )
}

export default Culture;