import styles from './Recruit.module.scss'
import { TECH_BLOG_URL } from '../constants/global';
import JobDescription from './JobDescription';

const Recruit = () => {
  return (
    <section>
      <div className={styles.recruitBgTop} />
      <div className={styles.contentWrapper}>
        <div className={styles.jobDescription}>
          <JobDescription/>
        </div>
      </div>
      <div className={styles.recruitBgBottom} />
      <div className={styles.blogLinkWrapper}>
        <a className={styles.blogLinkBox} href={TECH_BLOG_URL} target="_blank" rel="noreferrer">
          <div className={styles.blogLinkBoxInner}>
            <h2 id="techblog">TECH BLOG</h2>
            <p className={styles.blogLinkSubTitle}>エンジニアの技術ブログ</p>
            <p>iimonエンジニアが得られた経験や知識を共有して世の中をイイモンにしていくためのブログです</p>
          </div>
          <div className={styles.blogLinkImage} />
        </a>
      </div>
      <div className={styles.blogBgBottom} />
    </section>
  )
}

export default Recruit;