export const HEADLINES = [{
    name: 'ABOUT',
    href: '#about'
},{
    name: 'VISION',
    href: '#vision'
},{
    name: 'CULTURE',
    href: '#culture'
},{
    name: 'SERVICE',
    href: '#service'
},{
    name: 'INTERVIEW',
    href: '#interview'
},{
    name: 'IN NUMBERS',
    href: '#innumbers'
},{
    name: 'WELFARE',
    href: '#welfare'
},{
    name: 'RECRUIT',
    href: '#recruit'
},{
    name: 'TECH BLOG',
    href: '#techblog'
}]

// PCのヘッダーだけRECRUITを除いたものを表示
export const PC_HEADER_HEADLINES = HEADLINES.filter(HEADLINES => HEADLINES.name !== 'RECRUIT');

export const SP_WIDTH = 1200;
export const OFFER_LINK = 'https://www.wantedly.com/companies/company_2248610/projects';

const spStandardFontSize = 16;
export const spPx = (px: number) => {
    return (px / 390) * (780 / spStandardFontSize) + 'rem';
}

export const TECH_BLOG_URL = 'https://tech.iimon.co.jp/';