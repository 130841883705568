import { tracking } from '@iimon/analysis-tracking'

const sendTraking = async (eventName: string, params: {[key: string]: any}) => {
    // recruitは本番のみ
    if(process.env.REACT_APP_TARGET === 'prod') {
        await tracking('prod', 'recruit', eventName, params);
    }
}

// ページビューのtracking
export const trackingViewPage = async (path: string, params: string) => {
    const prm = new URLSearchParams(params);
    const source = prm.get('source');
    await sendTraking('recruitment-site-view-page', { path, source });
}

// どのセクションまでスクロールしたかのtracking
export const trackingScrollPage = async (path: string, section: string) => {
    await sendTraking('recruitment-site-scroll-page', { path, section });
}

// 募集詳細をクリックした時のtraking
export const trackingOfferDetailsClickLink = async (path: string, pos: 'OfferDetailsHeader' | 'OfferDetailsRecruitSection', linkUrl: string, linkName: string) => {
    await sendTraking('recruitment-site-click-link', { path, pos, link_url: linkUrl, link_name: linkName });
}

// メンバーインタビューをクリックした時のtraking
export const trackingMemberInterviewClickLink = async (path: string, linkUrl: string, linkName: string) => {
    await sendTraking('recruitment-site-click-link', { path, pos: 'MemberInterview', link_url: linkUrl, link_name: linkName });
}

// ボードメンバーインタビューをクリックした時のtraking
export const trackingBoardmemberInterviewClickLink = async (path: string, linkUrl: string, linkName: string) => {
    await sendTraking('recruitment-site-click-link', { path, pos: 'BoardmemberInterview', link_url: linkUrl, link_name: linkName });
}