import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Header from './components/Header';
import FirstView from './components/FirstView';
import About from './components/About';
import Vision from './components/Vision';
import Culture from './components/Culture';
import Service from './components/Service';
import Interview from './components/Interview/Interview';
import InNumbers from './components/InNumbers/InNumbers';
import Welfare from './components/Welfare';
import Recruit from './components/Recruit';
import Footer from './components/Footer';
import './styles/global.css'
//import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <FirstView />
    <About />
    <Vision />
    <Culture />
    <Service />
    <Interview />
    <InNumbers />
    <Welfare />
    <Recruit />
    <Footer />
    <Header />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
