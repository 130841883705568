import { useEffect, useState } from 'react';
import styles from './InNumbers.module.scss'
import { useInView } from '@react-spring/web';
import { trackingScrollPage } from '../../lib/tracking'
import { useWindowSizeKind } from '../../hooks/useWindowSizeKind'
import InNumbersModal from './InNumbersModal'
import { useCarouselScroll } from '../../hooks/useCarouselScroll';

export type EventType = {
  month: number;
  detail: string;
}

const EVENTS: {[key: string]: EventType[]} = {
  2019: [
    { month: 1, detail: '創業' },
  ],
  2020: [
    { month: 2, detail: '入力速いもんリリース' },
    { month: 9, detail: 'ディップ株式会社へOEM提供' },
  ],
  2021: [
    { month: 1, detail: 'ディップ株式会社との資本業務提携' },
    { month: 4, detail: 'オフィス引越し' },
    { month: 8, detail: 'プロダクトロゴ作成' },
    { month: 10, detail: '社員旅行 in 沖縄' },
  ],
  2022: [
    { month: 4, detail: 'シリーズAにて４億円の資金調達' },
    { month: 8, detail: '内定者チャンネル出演' },
    { month: 10, detail: '社員旅行 in 北海道' },
    { month: 12, detail: 'オフィス引越し' },
  ],
  2023: [
    { month: 4, detail: '新卒１期生入社' },
    { month: 10, detail: '社員旅行 in 沖縄' },
  ],
  2024: [
    { month: 4, detail: '【予定】新卒２期生入社' },
  ]
}

const Caption = ({headline, value, style}: {headline: string, value: string, style: string}) => {
  return (
    <div className={`${styles.caption} ${style}`}>
      <div className={styles.ratioHeadline}>{headline}</div>
      <div className={styles.ratioValue}><span>{value}</span>名</div>
    </div>
  )
}

const YearItems = ({events, year}: {events: EventType[], year: string}) => {
  return (
    <div className={styles.yearItem}>
      <p className={styles.yearItemYear}>{year}年</p>
      <ol className={styles.events}>
        {events.map((event, idx) => {
          return (
            <li key={idx} className={styles.eventItem}>
              <span>{event.month}月</span>
              <p>{event.detail}</p>
            </li>
          )
        })}
      </ol>
    </div>
  )
}

const InNumbers = () => {
  const [trackingRef, trackingInView] = useInView({once: true});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalYear, setModalYear] = useState('2022');
  const [ windowSizeKind ] = useWindowSizeKind();
  const item = document.getElementsByClassName(styles.yearItem)[0];
  const scrollElement = document.getElementsByClassName(styles.newsBase)[0] as HTMLElement;
  const [ itemIncrement, itemDecrement, currentItemNum ] = useCarouselScroll({item, scrollElement, maxItem: Object.keys(EVENTS).length - 1})

  const openModalAndSetYear = (year: string) => {
    setModalYear(year);
    setIsModalOpen(true);
  }

  useEffect(() => {
    if (trackingInView) {
      trackingScrollPage('/', 'innumbers');
    }
  }, [trackingInView]);

  return (
    <div className={styles.contentWrapper} id="innumbers" ref={trackingRef}>
      <h2>IN NUMBERS</h2>
      <h3>数字で見るiimon</h3>
      <div className={styles.figureNumber}>
        {
          windowSizeKind === 'PC' && (
            <>
              <div className={styles.figure2025}/>
              <div className={styles.figure2024} onClick={() => {openModalAndSetYear('2024')}}/>
              <div className={styles.figure2023} onClick={() => {openModalAndSetYear('2023')}}/>
              <div className={styles.figure2022} onClick={() => {openModalAndSetYear('2022')}}/>
              <div className={styles.figure2021} onClick={() => {openModalAndSetYear('2021')}}/>
              <div className={styles.figure2020} onClick={() => {openModalAndSetYear('2020')}}/>
              <div className={styles.figure2019} onClick={() => {openModalAndSetYear('2019')}}/>
            </>
          )
        }
        {
          windowSizeKind === 'SP' && (
            <>
              <h4 className={styles.newsTitle}>各年のニュース</h4>
              <div className={styles.newsWrapper}>
                <div className={styles.newsBase}>
                  {Object.keys(EVENTS).sort().reverse().map((year, idx) => {
                    return (
                      <YearItems events={EVENTS[year]} year={year}/>
                    )
                  })}
                </div>
                <div onClick={itemIncrement} className={styles.rightScroll} style={currentItemNum >= (Object.keys(EVENTS).length-1) ? {display: 'none'} : {}}/>
                <div onClick={itemDecrement} className={styles.leftScroll} style={currentItemNum <= 0 ? {display: 'none'} : {}}/>
              </div>
            </>
          )
        }
      </div>
      <ul className={styles.ratio}>
        <li className={styles.ratioBox}>
          <p className={styles.ratioTitle}>平均年齢</p>
          <div className={styles.ageRatio}>
            <Caption headline="20代" value="22" style={styles.twenties}/>
            <Caption headline="30代" value="18" style={styles.thirties} />
            <Caption headline="40代" value="5" style={styles.forties}/>
          </div>
          <p className={styles.ageText}><span>31</span>歳</p>
        </li>
        <li className={styles.ratioBox}>
          <p className={styles.ratioTitle}>男女割合</p>
          <div className={styles.genderRatio}>
            <Caption headline="男性" value="34" style={styles.male}/>
            <Caption headline="女性" value="11" style={styles.female}/>
          </div>
        </li>
        <li className={styles.ratioBox}>
          <p className={styles.ratioTitle}>部署人数割合</p>
          <div className={styles.departmentRatio}>
            <Caption headline="BO" value="8" style={styles.backoffice}/>
            <Caption headline="セールス" value="14" style={styles.salse}/>
            <Caption headline="エンジニア" value="17" style={styles.engineer}/>
          </div>
          <p className={styles.departmentAnnotation}>※役員除く</p>
        </li>
      </ul>
      <div className={styles.annotation}>※2024年1月時点の情報です</div>
      {
        windowSizeKind === 'PC' &&
        <InNumbersModal events={EVENTS} year={modalYear} isOpen={isModalOpen} setIsOpen={setIsModalOpen}/>
      }
    </div>
  )
}

export default InNumbers;