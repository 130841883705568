import React, { useState, useEffect } from 'react';
import interviews from '../../contents/interviews.json';
import boardMembers from '../../contents/boardMembers.json';
import boardMemberInterviews from '../../contents/boardMemberInterviews.json';
import BoardMemberModal from './BoardMemberModal';
import JobDescription from '../JobDescription';
import styles from './Interview.module.scss'
import { useWindowSizeKind } from '../../hooks/useWindowSizeKind'
import { useInView } from '@react-spring/web';
import { trackingMemberInterviewClickLink, trackingBoardmemberInterviewClickLink, trackingScrollPage } from '../../lib/tracking'

export type BoardMemberType = (typeof boardMembers)[number];

const clickMemberInterviewLink = (linkUrl: string, linkName: string) => {
  trackingMemberInterviewClickLink('/', linkUrl, linkName);
}

const clickBoardmemberInterviewLink = (linkUrl: string, linkName: string) => {
  trackingBoardmemberInterviewClickLink('/', linkUrl, linkName);
}

const Interview = () => {
  const [ windowSizeKind ] = useWindowSizeKind();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedBoardMember, setSelectedBoardMember] = useState({ name: '', position: '', detail: '', img: '' });
  const [trackingRef, trackingInView] = useInView({once: true});

  const openModal = (boardMember: BoardMemberType) => {
    setSelectedBoardMember(boardMember);
    setIsModalOpen(true);
  }

  useEffect(() => {
    if (trackingInView) {
      trackingScrollPage('/', 'interview');
    }
  }, [trackingInView]);

  return (
    <section ref={trackingRef}>
      <div className={styles.bgTop} id="interview" />
      <div className={styles.contentWrapper}>
        <div className={styles.bgRed}/>
        <h2 className={styles.styleTitle}>INTERVIEW</h2>
        <h3 className={styles.subTitle}>社員インタビュー</h3>
        <ul className={styles.interviewWrapper}>
          {interviews.map((interview, idx) => {
            return (
              <li key={idx} className={styles.interviewCard}>
                <a href={interview.url} target="_blank" rel="noreferrer" onClick={() => {clickMemberInterviewLink(interview.url, interview.title)}}>
                  <img src={interview.img} alt={interview.title} className={styles.interviewImg}/>
                </a>
              </li>
            )
          })}
        </ul>
        <div className={styles.interviewSubTitleWrapper}>
          <h3 className={styles.subTitle}>ボードメンバー</h3>
        </div>
        <div className={styles.boardmemberButtons}>
          <div className={styles.boardmemberWrapper}>
            <ul className={styles.boardmemberInner}>
              {boardMembers.map((boardMember, idx) => {
                return (
                  <div key={idx} className={styles.boardmemberCardWrapper}>
                    <li className={styles.boardmemberCard}>
                      <div onClick={() => openModal(boardMember)} className={styles.boardmemberCardWrapper}>
                        <img src={boardMember.img} alt={`${boardMember.position} ${boardMember.name}`} className={styles.boardmemberCardImg}/>
                      </div>
                    </li>
                  </div>
                )
              })}
            </ul>
          </div>
        </div>
        <BoardMemberModal
          boardMember={selectedBoardMember}
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
        />
        <h3 className={styles.subTitle}>ボードメンバーインタビュー</h3>
        <ul className={styles.boardMemberInterview}>
          {boardMemberInterviews.map((interview, idx) => {
            return (
              <li key={idx}>
                <div className={styles.boardMemberInterviewCard}>
                  <a href={interview.url} target="_blank" rel="noreferrer" className={styles.boardMemberInterviewLink} onClick={() => {clickBoardmemberInterviewLink(interview.url, interview.title)}}>
                    <img src={windowSizeKind === 'PC' ? interview.imgPc : interview.imgSp} alt={interview.title} className={styles.boardMemberInterviewImg} />
                    <div className={styles.boardMemberInterviewTitleWrapper}>
                      <p className={styles.boardMemberInterviewTitle}>{interview.title}</p>
                      <p className={styles.boardMemberInterviewSubTitle}>{interview.subTitle}</p>
                    </div>
                  </a>
                </div>
              </li>
            )
          })}
        </ul>
        <div className={styles.jobDescription}>
          <JobDescription/>
        </div>
      </div>
      <div className={styles.bgBottom}>
        <div className={styles.bgYellow}/>
      </div>
    </section>
  )
}

export default Interview;