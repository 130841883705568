import React, { useEffect } from 'react';
import styles from './Welfare.module.scss';
import images from '../contents/welfareImages.json';
import { useWindowSizeKind } from '../hooks/useWindowSizeKind';
import { useCarouselScroll } from '../hooks/useCarouselScroll';
import { useInView } from '@react-spring/web';
import { trackingScrollPage } from '../lib/tracking';
import { ReactComponent as EditorIcon } from '../assets/svg/welfare_icons/editor.svg';
import { ReactComponent as GitHubIcon } from '../assets/svg/welfare_icons/github.svg';
import { ReactComponent as BookIcon } from '../assets/svg/welfare_icons/book.svg';
import { ReactComponent as ClockIcon } from '../assets/svg/welfare_icons/clock.svg';
import { ReactComponent as StandingDeskIcon } from '../assets/svg/welfare_icons/standing_desk.svg';
import { ReactComponent as LaptopIcon } from '../assets/svg/welfare_icons/laptop.svg';
import { ReactComponent as DisplayIcon } from '../assets/svg/welfare_icons/display.svg';
import { ReactComponent as MouseIcon } from '../assets/svg/welfare_icons/mouse.svg';
import { ReactComponent as SpoonIcon } from '../assets/svg/welfare_icons/spoon.svg';
import { ReactComponent as BowlingIcon } from '../assets/svg/welfare_icons/bowling.svg';
import { ReactComponent as AirplaneIcon } from '../assets/svg/welfare_icons/airplane.svg';
import { ReactComponent as ReferralIcon } from '../assets/svg/welfare_icons/referral.svg';
import { ReactComponent as CalendarIcon } from '../assets/svg/welfare_icons/calendar.svg';
import { ReactComponent as DrinkIcon } from '../assets/svg/welfare_icons/drink.svg';
import { ReactComponent as RiceIcon } from '../assets/svg/welfare_icons/rice.svg';

const WelfareItem = ({title, detail, Icon}: {title: string, detail?: string, Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement> & {
  title?: string | undefined;
}>}) => {
  return (
    <li className={styles.item}>
      <Icon className={styles.itemIcon} />
      <div className={styles.itemTitleWrapper}>
        <div className={styles.itemTitle}>{title}</div>
        {detail && <div className={styles.itemAddition}>{detail}</div>}
      </div>
    </li>
  )
}

const Welfare = () => {
  const [ windowSizeKind ] = useWindowSizeKind();
  const item = document.getElementsByClassName(styles.welfareImage)[0];
  const scrollElement = document.getElementsByClassName(styles.welfareImages)[0] as HTMLElement;
  const [ itemIncrement, itemDecrement, currentItemNum ] = useCarouselScroll({item, scrollElement, maxItem: images.length - 1})
  const [trackingRef, trackingInView] = useInView({once: true});

  useEffect(() => {
    if (trackingInView) {
      trackingScrollPage('/', 'welfare');
    }
  }, [trackingInView]);

  return (
    <section ref={trackingRef}>
      <div className={styles.welfareBgTop} id={windowSizeKind === 'PC' ? 'welfare' : ''} />
      <div className={styles.contentWrapper} id={windowSizeKind === 'SP' ? 'welfare' : ''}>
        <div className={styles.bgBlue} />
        <div className={styles.welfareItems}>
          <h2>WELFARE</h2>
          <h3 className={styles.welfareSubtitle}>福利厚生・社内制度</h3>
          <p>社員一人ひとりが活躍できる職場であるために、iimonは個人の多様性を尊重しています。<br/>仕事とライフイベントを両立するための休暇制度や安心・快適に働くための福利厚生制度や{windowSizeKind === 'PC' && <br/>}社員同時の交流をサポートするレクリエーションなど、{windowSizeKind === 'PC' && <br/>}社員一人ひとりの活躍とライフ・ワーク・バランスの実現をサポートしていきます。</p>
          <h3 className={styles.welfareKind}>快適な仕事環境づくり</h3>
          <ul className={styles.items}>
            <WelfareItem title="エディタ費用支給" Icon={EditorIcon} />
            <WelfareItem title="GitHub Copilotの権限付与" Icon={GitHubIcon} />
            <WelfareItem title="書籍購入制度" Icon={BookIcon} />
            <WelfareItem title="フレックス制度" detail="コアタイム11:00~17:00" Icon={ClockIcon} />
            <WelfareItem title="スタンディングデスク貸与(卓上型)" Icon={StandingDeskIcon} />
            <WelfareItem title="ハイスペックPC貸与" detail="(例：MacBookPro、32GBメモリ、11コアCPU)" Icon={LaptopIcon} />
            <WelfareItem title="ディスプレイ貸与" detail="(２台まで)" Icon={DisplayIcon} />
            <WelfareItem title="PC周辺機器支給" detail="(マウス、イヤホンマイク)" Icon={MouseIcon} />
          </ul>
          <h3 className={styles.welfareKind}>その他</h3>
          <ul className={styles.items}>
            <WelfareItem title="シャッフルランチ" Icon={SpoonIcon} />
            <WelfareItem title="レクリエーション" detail="(例：ボウリング、BBQ)" Icon={BowlingIcon} />
            <WelfareItem title="社員旅行" detail="2023年は沖縄でした！" Icon={AirplaneIcon} />
            <WelfareItem title="リファラル採用制度" Icon={ReferralIcon} />
            <WelfareItem title="イベント費用支給" detail="(例：DevelopersIO)" Icon={CalendarIcon} />
            <WelfareItem title="ドリンク飲み放題" Icon={DrinkIcon} />
            <WelfareItem title="お米食べ放題" detail="会社のお米を食べてOK！" Icon={RiceIcon} />
          </ul>
        </div>
      </div>
      <div className={styles.welfareImagesWrapper}>
        <div className={styles.welfareImagesInner}>
          <ul className={styles.welfareImages}>
            {images.map((image, idx) => {
              return (
                <li key={idx} className={styles.welfareImage}>
                  <img src={image.src} alt={image.alt} className={styles.welfareImageRadius}/>
                </li>
              )
            })}
          </ul>
        </div>
        {windowSizeKind === 'SP' && (
          <React.Fragment>
            <div onClick={itemDecrement} className={styles.leftScroll} style={currentItemNum <= 0 ? {display: 'none'} : {}}/>
            <div onClick={itemIncrement} className={styles.rightScroll} style={currentItemNum >= (images.length-1) ? {display: 'none'} : {}}/>
          </React.Fragment>
        )}
      </div>
      <div className={styles.welfareBgBottom} />
    </section>
  )
}

export default Welfare;
