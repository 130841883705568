import { useState, useEffect } from 'react';
import { SP_WIDTH } from '../constants/global';

type WindowSizeKindType = 'PC' | 'SP'

export const useWindowSizeKind = (): [WindowSizeKindType] => {
  const [windowSizeKind, setWindowSizeKind] = useState<WindowSizeKindType>('PC');

  useEffect(() => {
    const updater = () => {
      if(window.innerWidth <= SP_WIDTH) {
        setWindowSizeKind('SP');
      } else {
        setWindowSizeKind('PC');
      }
    };
    window.addEventListener('resize', updater, false);
    updater();
    return () => {
      window.removeEventListener('resize', updater, false);
    };
  }, []);

  return [ windowSizeKind ];
}