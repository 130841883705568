import React, { useEffect } from 'react';
import styles from './About.module.scss'
import { useInView } from '@react-spring/web';
import { sequenceAnimation } from '../animationUtil';
import { trackingScrollPage } from '../lib/tracking'

const About = () => {
  const [ref, inView] = useInView({once: true});
  const [trackingRef, trackingInView] = useInView({once: true});

  useEffect(() => {
    if (inView) {
      sequenceAnimation([{
        delay: 200,
        targetClassName: styles.imageSmall,
        addClassName: styles.imageSmallVisible
      }, {
        delay: 500,
        targetClassName: styles.imageLarge,
        addClassName: styles.imageLargeVisible
      }]);
    }
  }, [inView]);

  useEffect(() => {
    if (trackingInView) {
      trackingScrollPage('/', 'about');
    }
  }, [trackingInView]);

  return (
    <section ref={trackingRef}>
      <div className={styles.aboutBgTop}>
        <div className={styles.blueBg} />
      </div>
      <div className={styles.contentWrapper} id="about">
        <h2>ABOUT</h2>
        <div className={styles.contentInner}>
          <div className={styles.textWrapper}>
            <p>多くの不動産企業が、既成概念や慣習に囚われて、「これが当たり前」と非合理なやり方や仕組みを受け入れている。そんな不動産仲介業務の課題を、次々と解決していく。それが私たちの使命です。</p>
            <p>これまで私たちは、主に物件入力を効率化するサービスを届けてきましたが、これからは不動産仲介業務すべての課題を解決できるプロダクトを目指して拡大をしていきます。</p>
            <p>もっと、「心」が必要な、「人」がやるべき業務に集中できるように。もっと、心や時間に「豊かさ」をもって顧客のためを考えられるように。</p>
            <p>iimonは、不動産仲介業務を経験してきた現場のノウハウで不動産仲介業務をハックし、不動産会社の日常を一変させるプロダクトをつくり続けます。</p>
          </div>
          <div className={styles.imageWrapper}>
            <div className={styles.imageSmall} ref={ref}/>
            <div className={styles.imageLarge} />
          </div>
        </div>
      </div>
      <div className={styles.aboutBgBottom}/>
    </section>
  );
}

export default About;