import styles from './JobDescription.module.scss';
import { useWindowSizeKind } from '../hooks/useWindowSizeKind';

type JobProps = {
  jobName: string;
  link: string;
  bgImgStyle: string;
};

const Job = ({ jobName, link, bgImgStyle }: JobProps) => {
  return (
    <a href={link} className={styles.link} target="_blank" rel="noreferrer">
      <div className={`${styles.bg} ${bgImgStyle}`}>
        <div className={styles.inner}>
          <div dangerouslySetInnerHTML={{__html: jobName}}></div>
        </div>
      </div>
    </a>
  );
}

const JobDescription = () => {
  const [ windowSizeKind ] = useWindowSizeKind();

  return (
    <div>
      <h2 className={styles.title}>iimonでは{windowSizeKind === 'SP' && (<br/>)}エンジニアを募集しています！<br/>私たちと一緒に働きませんか？</h2>
      <ul className={styles.jobList}>
        <li>
          <Job jobName="SREチームリーダー" link="https://bead-art-1d1.notion.site/SRE-1376528f2571801e8c79d1b56c0d3e67" bgImgStyle={styles.bgImg02} />
        </li>
        <li>
          <Job jobName="モバイルアプリエンジニア<br/>(Flutter)" link="https://bead-art-1d1.notion.site/Flutter-1376528f257180b2bfc5c152d2c38207" bgImgStyle={styles.bgImg02} />
        </li>
        <li>
          <Job jobName="シニアソフトウェア<br/>アーキテクト" link="https://bead-art-1d1.notion.site/1356528f257180deb2cacaa7f7785969" bgImgStyle={styles.bgImg01} />
        </li>
      </ul>
    </div>
  );
};

export default JobDescription;
