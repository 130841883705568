import React, { useEffect } from 'react';
import { useTransition, animated } from "react-spring";
import styles from './BoardMemberModal.module.scss'
import { BoardMemberType } from './Interview'

type BoardMemberModalPropsType = {
  boardMember: BoardMemberType,
  isModalOpen: boolean,
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const BoardMemberModal = ({ boardMember, isModalOpen, setIsModalOpen }: BoardMemberModalPropsType) => {
  const closeModal = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if(event.target === event.currentTarget) setIsModalOpen(false)
  }
  const transitions = useTransition(isModalOpen, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: {
      duration: 200
    }
  });

  useEffect(() => {
    if(isModalOpen) {
      const body = document.querySelector("body");
      if (!body) return;
      body.style.overflow = "hidden";
    } else {
      const body = document.querySelector("body");
      if (!body) return;
      body.style.overflow = "visible";
    }

    return () => {
      const body = document.querySelector("body");
      if (!body) return;
      body.style.overflow = "visible";
    }
  }, [isModalOpen])

  return (
    <React.Fragment>
      {transitions((style, item) => (
        item && (
          <div className={styles.background} onClick={closeModal}>
            <animated.div style={style}>
              <div className={styles.modalWrapper}>
                <div className={styles.leftWrapper}>
                  <img src={boardMember.img} alt={boardMember.name} className={styles.memberImg} />
                </div>
                <div className={styles.rightWrapper}>
                  <div>
                    <p className={styles.position}>{boardMember.position}</p>
                    <h3 className={styles.name}>{boardMember.name}</h3>
                  </div>
                  <div>
                    <p className={styles.detail}>{boardMember.detail}</p>
                  </div>
                </div>
                <div className={styles.closeButton} onClick={() => setIsModalOpen(false)} />
              </div>
            </animated.div>
          </div>
        )
      ))}
    </React.Fragment>
  )
}

export default BoardMemberModal;