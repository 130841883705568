import { useEffect } from 'react';
import styles from './Service.module.scss';
import { useWindowSizeKind } from '../hooks/useWindowSizeKind';
import { useTransition, useInView, animated } from '@react-spring/web';
import { trackingScrollPage } from '../lib/tracking'

const tasks = [
  "ポータルサイトへの物件情報入力",
  "物件情報の募集状況の確認",
  "店舗のマーケティング",
  "物件情報のメール / メッセージ送付",
  "紙情報でのやり取りによる非効率業務"
]

const usages = [
  (<div className={styles.usageBox}>
    <div>月間継続率</div>
    <div><span className={styles.usagePersistence}>98.6</span>%</div>
  </div>),
  (<div className={styles.usageBox}>
    <div>月間利用回数</div>
    <div><span className={styles.usageCount}>120</span>万回以上</div>
  </div>),
  (<div className={styles.usageBox}>
    <div>導入店舗数</div>
    <div><span className={styles.usageStore}>2,500</span>店舗以上</div>
  </div>)
]

const Service = () => {
  const [ windowSizeKind ] = useWindowSizeKind();
  const [taskTransitions, taskApi] = useTransition(tasks, () => ({
    delay: 500,
    from: { opacity: 0 },
    enter: { opacity: 1 },
    config: {
      duration: 700
    },
    trail: 500,
  }))
  const [taskRef, taskInView] = useInView({once: true});

  const [usageTransitions, usageApi] = useTransition(usages, () => ({
    delay: 500,
    from: { opacity: 0, transform: 'scale(1.7)' },
    enter: { opacity: 1, transform: 'scale(1)' },
    config: {
      mass: 1,
      tension: 1000,
      friction: 35,
    },
    trail: 500,
  }))
  const [usageRef, usageInView] = useInView({once: true});
  const [trackingRef, trackingInView] = useInView({once: true});

  useEffect(() => {
    if (taskInView) {
      taskApi.start();
    }
  }, [taskInView]);

  useEffect(() => {
    if (usageInView) {
      usageApi.start();
    }
  }, [usageInView]);

  useEffect(() => {
    if (trackingInView) {
      trackingScrollPage('/', 'service');
    }
  }, [trackingInView]);

  return (
    <div className={styles.contentWrapper} id="service" ref={trackingRef}>
      <div className={styles.bgBlue}/>
      <h2 className={styles.title}>SERVICE</h2>
      <div className={styles.serviceInner}>
        <div className={styles.buisinessBox}>
          <div className={styles.buisinessTitle}>
            不動産仲介の事務業務は<br/>全業務の約<span>50<div className={styles.percent}>%</div></span>程度を{windowSizeKind === 'SP' && (<br/>)}占めると考えています
          </div>
          <div className={styles.buisinessInner}>
            <div>
              <p className={styles.task}>やるべき業務は営業活動以外に<br/>こんなにたくさん…</p>
              <ul className={styles.taskList} ref={taskRef}>
                {taskInView && taskTransitions((style, item) => (
                  <animated.li style={style}>{item}</animated.li>
                ))}
              </ul>
            </div>
            {windowSizeKind === 'PC' && (
              <div className={styles.pie}><span>50%</span></div>
            )}
            <div className={styles.taskRate}>
              <img src="/img/pc/service_illustration.png" alt="やるべき業務は営業活動以外にこんなにたくさん" className={styles.serviceIllustration} />
              {windowSizeKind === 'SP' && (
                <div className={styles.pie}><span>50%</span></div>
              )}
            </div>
          </div>
        </div>
        <div className={styles.triangle} />
        <p className={styles.resolution}>そんな課題を{windowSizeKind === 'SP' && (<br/>)}<span>iimon</span>が解決！</p>
        <div className={styles.figureBox}>
          <p>不動産業に関わる業務の自動化・効率化・生産性を向上させるシステムを<br/>SaaSとして提供しています。</p>
          {windowSizeKind === 'PC' && (
            <img src="/img/pc/service_figure.png" alt="速いもんシリーズ" className={styles.serviceFigure} />
          )}
          {windowSizeKind === 'SP' && (
            <img src="/img/sp/service_figure.png" alt="速いもんシリーズ" className={styles.serviceFigure} />
          )}
        </div>
        <div className={styles.usages} ref={usageRef}>
          {usageInView && usageTransitions((style, item) => (
            <animated.div style={style}>{item}</animated.div>
          ))}
        </div>
        <small className={styles.annotation}>※2023年11月時点の情報です</small>
      </div>
    </div>
  )
}

export default Service;