import { useState, useEffect } from 'react';
import { useWindowSizeKind } from './useWindowSizeKind'

type CarouselScrollPropsType = {
  maxItem: number
  item: Element
  scrollElement: HTMLElement
}

export const useCarouselScroll = ({ maxItem, item, scrollElement }: CarouselScrollPropsType): [()=>void, ()=>void, number] => {
  const [num, setNum] = useState(0);
  const [ windowSizeKind ] = useWindowSizeKind();

  useEffect(() => {
    const updater = () => {
      const itemWidth = item?.clientWidth;
      if(scrollElement && windowSizeKind === 'PC') {
        setNum(0);
        scrollElement.style.transform = `translateX(0)`;
      } else if(scrollElement && itemWidth){
        scrollElement.style.transform = `translateX(-${itemWidth * num}px)`;
      }
    }
    window.addEventListener('resize', updater, false);
    updater();

    return () => {
      window.removeEventListener('resize', updater, false);
    };
  }, [num, windowSizeKind, item?.clientWidth, scrollElement]);

  const setItemNum = (num: number) => {
    if(num < 0) {
      setNum(0);
    } else if(num > maxItem) {
      setNum(maxItem);
    } else {
      setNum(num);
    }
  }

  const increment = () => {
    if(num > maxItem) {
      setNum(maxItem);
    } else {
      setItemNum(num + 1);
    }
  }

  const decrement = () => {
    if(num < 0) {
      setNum(0);
    } else {
      setItemNum(num - 1);
    }
  }

  return [increment, decrement, num];
}