import React, { useEffect } from 'react';
import { useTransition, animated } from "react-spring";
import styles from './InNumbersModal.module.scss'
import { EventType } from './InNumbers'

type InNumbersModalProps = {
  events: {[key: string]: EventType[]};
  year: string;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const InNumbersModal = ({ events, year, isOpen, setIsOpen }: InNumbersModalProps) => {
  const closeModal = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if(event.target === event.currentTarget) setIsOpen(false)
  }
  const transitions = useTransition(isOpen, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: {
      duration: 200
    }
  });

  useEffect(() => {
    if(isOpen) {
      const body = document.querySelector("body");
      if (!body) return;
      body.style.overflow = "hidden";
    } else {
      const body = document.querySelector("body");
      if (!body) return;
      body.style.overflow = "visible";
    }

    return () => {
      const body = document.querySelector("body");
      if (!body) return;
      body.style.overflow = "visible";
    }
  }, [isOpen])

  return (
    <React.Fragment>
      {transitions((style, item) => (
        item && (
          <div className={styles.background} onClick={closeModal}>
            <animated.div style={style}>
              <div className={styles.modalWrapper}>
                <div className={styles.modalInner}>
                  <div className={styles.graphContainer}>
                    <div>
                      <div className={styles[`graph${year}`] + ` ${styles.graph}`} />
                      <div className={styles.graphYear}>{year}</div>
                    </div>
                    <div>
                      <p className={styles.eventsTitle}>{year}年のできごと</p>
                      <div className={styles.events}>
                        <ol className={styles.eventList}>
                          {events[year].map((event, idx) => {
                            return (
                              <li key={idx} className={styles.eventItem}>
                                <span>{`${year}.${event.month}`}</span>
                                <p>{event.detail}</p>
                              </li>
                            )
                          })}
                        </ol>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.closeButton} onClick={closeModal}/>
              </div>
            </animated.div>
          </div>
        )
      ))}
    </React.Fragment>
  );
};

export default InNumbersModal;
