import React, { useEffect } from 'react';
import nineStyles from '../contents/styles.json';
import styles from './Vision.module.scss';
import { useWindowSizeKind } from '../hooks/useWindowSizeKind';
import { useCarouselScroll } from '../hooks/useCarouselScroll';
import { useInView, animated } from '@react-spring/web';
import { sequenceAnimation } from '../animationUtil';
import { trackingScrollPage } from '../lib/tracking'

const Vision = () => {
  const [ windowSizeKind ] = useWindowSizeKind();
  const item = document.getElementsByClassName(styles.nineStylesBoxWrapper)[0];
  const scrollElement = document.getElementsByClassName(styles.nineStylesInner)[0] as HTMLElement;
  const [ itemIncrement, itemDecrement, currentItemNum ] = useCarouselScroll({item, scrollElement, maxItem: nineStyles.length - 1})
  const [trackingRef, trackingInView] = useInView({once: true});

  const animationConfig = {
    from: {
      opacity: 0,
      transform: 'scale(0.1)',
    },
    to: {
      opacity: 1,
      transform: 'scale(1.0)',
    },
    config: {
      mass: 1,
      tension: 500,
      friction: 20,
    }
  }
  const args = {
    rootMargin: '-20% 0%',
    once: true,
  };
  const [ref1, springs1] = useInView(
    () => (animationConfig),
    args
  );
  const [ref2, springs2] = useInView(
    () => (animationConfig),
    args
  );
  const [missionRef, missionInView] = useInView();

  useEffect(() => {
    if (missionInView) {
      sequenceAnimation([{
        delay: 300,
        targetClassName: styles.missionSubTitleAnim,
        addClassName: styles.missionSubTitleAnimVisible
      }, {
        delay: 500,
        targetClassName: styles.visionIllustrationBulb,
        addClassName: styles.visionIllustrationBulbVibration
      }])
    }
  }, [missionInView])

  useEffect(() => {
    if (trackingInView) {
      trackingScrollPage('/', 'vision');
    }
  }, [trackingInView]);

  return (
    <section className={styles.visionWrapper} id="vision" ref={trackingRef}>
      <div className={styles.bgBlueTop} />
      <div className={styles.missionWrapper}>
        <div className={styles.missionText}>
          <h2 className={styles.missionTitle}>MISSION</h2>
          <h3 className={styles.missionSubTitle} ref={missionRef}><span className={styles.missionSubTitleAnim}>不動産業界を</span><br/><span className={styles.missionSubTitleAnim}>「アイデア」でもっと良くする</span></h3>
        </div>
        <img src="/img/pc/mission_illustration_bulb.png" alt="イイモンで不動産仲介業務をハックする" className={styles.visionIllustrationBulb} />
        <img src="/img/pc/mission_illustration_people.png" alt="イイモンで不動産仲介業務をハックする" className={styles.visionIllustrationPeople} />
        <div className={styles.bgBlueBottom} />
        <div className={styles.contentsWrapper}>
          <h2 className={styles.visionTitle}>VISION</h2>
          <p className={styles.visionDetail}>イイモン(良い者)たちが集まり、<br/>イイモン(良いモノ)を創造し、<br/>夢に溢れたイイモン(良い門)を開く。</p>
          <p className={`${styles.visionDetail} ${styles.visionDeatailPolicy}`}>そしてその向こうに、<br/><span>“IIMON WORLD”</span>がきっとある！</p>
          <animated.div ref={ref2} style={springs2} className={styles.rightPic1Bg}>
            <div className={styles.rightPic1} />
          </animated.div>
          <animated.div ref={ref1} style={springs1} className={styles.rightPic0Bg}>
            <div className={styles.rightPic0} />
          </animated.div>
        </div>
      </div>
      <div className={styles.styleWrapper}>
        <h2 className={styles.styleTitle}>9STYLES</h2>
        <h3 className={styles.styleSubTitle}>9つのスタイル</h3>
        <div className={styles.nineStylesWrapper}>
          <div className={styles.nineStylesScroll}>
            <ol className={styles.nineStylesInner}>
              {nineStyles.map((style, idx) => {
                return (
                  <li key={idx} className={styles.nineStylesBoxWrapper}>
                    <div className={styles.nineStylesBox}>
                      <p className={styles.nineStylesTitle}>{style.title}</p>
                      <p className={styles.nineStylesSubTitle}>{style.subtitle}</p>
                      <p className={styles.nineStylesDetail} dangerouslySetInnerHTML={{__html: style.detail}}></p>
                    </div>
                  </li>
                )
              })}
            </ol>
          </div>
          {windowSizeKind === 'SP' && (
            <React.Fragment>
              <div onClick={itemDecrement} className={styles.leftScroll} style={currentItemNum <= 0 ? {display: 'none'} : {}}/>
              <div onClick={itemIncrement} className={styles.rightScroll} style={currentItemNum >= (nineStyles.length-1) ? {display: 'none'} : {}}/>
            </React.Fragment>
          )}
        </div>
      </div>
    </section>
  )
}

export default Vision;